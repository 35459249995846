@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Poppins:wght@200;300;400;500;600&display=swap');
body {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
  font-family:'Poppins';
}
.footer {
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  
  text-align: center;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  border-top: 1px solid #ddd;
  font-size: 11px; */

  padding: 2px;
  position: fixed;
  font-family:Poppins;
  font-size: 11px;
  line-height: 1.6;
  word-spacing: normal;

  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
}
.nav {
  list-style-type: none;
  margin: 0;

  padding: 10px;
  overflow: hidden;
  background-color: #04aa6d;

  top: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  position: sticky;
  z-index: 2;

  border-bottom: 2px solid #ebd302;
 list-style:none;
 display:"flex";justify-content:flex-start;align-items:center
}

.nav li {
  float: left;
}

.nav li a {
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.nav li a:hover:not(.active) {
  background-color: #fff;
  color: black;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  width: 95%;
  min-height: 80vh;
  margin-bottom: 20px;
}
.column {
  /* float: left; */
  padding: 8px;
  margin: 10px;
}
.column.side {
  width: 20%;
  background-color: transparent;
  border-radius: 5px;
  /* min-height: 80vh; */
  /* max-height: auto; */
  padding:0;
  height: 83vh;
}
.column.right {
  width: 15%;
  min-height: 80vh;
  max-height:80vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
.column.middle {
  width: 70%;
  background-color: white;
  border-radius: 5px;
  min-height: 83vh;
  /* max-height:80vh; */
  overflow-y: auto;
  scrollbar-width: thin;
}

.active {
  background-color: #04aa6d;
}

.sidelist ul {
  list-style-type: none;
}
.sideitems {
  display: block;
  padding: 0;
  text-align: left;
  /* border-bottom: 1px solid #ddd; */
  border-bottom: 1.5px solid #acacac;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  padding: 16px 5px;
}

.sideitems:hover{
  background-color: #ddd;
  cursor: pointer;
  border-left: 3px solid #04aa6d;
}

.sideitems button {
  display: block;
  /* background-color: white; */
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  padding: 16px 5px;
}
.sideitems button:hover {
  background-color: #ddd;
  cursor: pointer;
}

.enter_arrow{
  color: rgb(102, 101, 101);
}

.enter_arrow:hover{
  cursor: pointer;
 color: #04aa6d;
}
.sideitems:hover .enter_arrow{
  cursor: pointer;
  color: #04aa6d;
}
.sideitems li:last-child {
  border-bottom: none;
}
.header_title {
  display: block;
  border-bottom: 2px solid #04aa6d;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.body_text {
  padding: 5;
  text-align: left;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  word-spacing: normal;
}
.formWrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.formInput{
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start;
  gap:10px
}
input,
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;
}
/* input[type=textarea] {
  width: 70%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;
} */
/* input[type="radio"] {
  width: 10%;

  border: 1px solid #ccc;

  margin-top: 6px;
  box-sizing: border-box;
} */

.radio_header {
  width: 10%;
  margin-top: 15px;
  box-sizing: border-box;
  align-items: center;
  display: block;
}

label {
  padding: 3px 3px 3px 0;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
}

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  box-sizing: border-box;
}

input[type="submit"]:hover {
  background-color: #45a049;
  box-sizing: border-box;
}

.col-60 {
  float: left;
  width: 60%;
  margin-top: 2px;
  box-sizing: border-box;
}

.col-60 label{
  font-size: 16px;
  color:#272626;
  line-height: normal;
  letter-spacing: normal;
}


.success_box{
    
            padding: 10px;
            width: 99%;
            margin: 10px;
            box-sizing: border-box;
            background-color: #def5ed;
            border-left:  solid green;
           
        
}
.error_box{
    
            padding: 10px;
            width: 99%;
            margin: 10px;
            box-sizing: border-box;
            background-color: #ffe3e3;
            border-left:  solid crimson;
            width:100%
        
}
.col-20 {
  float: left;
  width: 28%;
  margin-top: 6px;
  box-sizing: border-box;
}
.col-80 {
  float: left;
  width: 70%;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}

.col-40 {
  float: left;
  width: 40%;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}
.col-40 .radio{
  width: 10px;
  color:"red"
}
.col-100 {
  float: left;
  width: 100%;
  margin-top: 6px;
}
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
  box-sizing: border-box;
}
.col-50 {
  float: left;
  width: 50%;
  margin-top: 6px;
}
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
  box-sizing: border-box;
}
.questions {
  border-bottom: 1px solid #ccc;
}

/*course units*/

.unitsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  cursor: pointer;
}

.unitsWrapper:hover {
  background-color: #def5ed;
  border-left: 3px solid green;
  
}

.notificationWrapper{
  background-color: rgb(250, 231, 219);
  border-left: 3px solid  rgb(128, 58, 0);
  padding:10px;
}
.toggleMenu {
 
  cursor: pointer;
  display:none
}






/*login styling*/

.loginInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
}

.loginOuterPrimary {
  height: 100vh;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loginOuterSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius:8px
  /* flex-wrap: wrap;  */
  
}


.item {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 50px;
  width: 100%;
  background-color: #ddd;
  row-gap: 2em;
}
.itemRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 300px;
  margin: 0px;
  flex-grow: 1;
}
.imageWrapper{
   display: flex;
                  justify-content:center;
                  align-items:center;
                  /* flex-grow: 1; */
}


  .button.secondary a {
    padding: 8px 8px;
    margin: 0;
    background-color: #507b50;
    color: #f5f4f4;
    border:none;
    border-radius:5px
  }
  .button.secondary a:hover {
    background-color: #97cc8d;
  }

  .user-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
   
    
  }
  .user-img{
    width:40px;
    border-radius: 100%;
    border: 1px solid #eee;
  }

  .navItem{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .navItem .user-img {
    width:30px;
    border-radius: 100%;
    border: 1px solid #eee;
  }

  .profile-image .user-img{
    width:40px;
    border-radius: 100%;
    border: 2px solid #eeeeee;
  }

  .user-details{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .grey-icons{
    color:#686262;
    font-size:25px;
  }

  .side{
   position:relative;
  margin-top: 20px;
  }

  
  .side ul li{
    position: relative;
    list-style-type:none;
    height: 40px;
    width: 100%;
    margin:0px auto;
    font-size: 14px;
    line-height: 40px;
    margin:10px
    
   
  }
/*
  .side ul li :hover{
   cursor: pointer;
   background-color: #283e55;
  }
  
*/
   .side ul li a{
   
    display: flex;
    align-items: center;
    text-decoration: none;
   border-radius: 0.8rem; 
    padding-left: 10px;
    gap:10px;
    color:#3c3d3c
    
    
  }  
  .nav-item{
    /* min-width: 50px;
    text-align: center;
    height: 50px;
    border-radius: 12px;
    line-height: 50px;  */
    /* background-color: skyblue; */

    height: 40px;
    display:flex;
    justify-content: center;
    align-items:center
    
    
    
    
   } 
  .side ul li a:hover{
   
    background-color: #ccc;
    color: #272626;
    
    
  } 
  
  /* .side
 ul li :hover{
  background-color:#ccc;
  color:#12171e;
 } */

 .side ul li a nav-icon{
  /* min-width: 50px;
  text-align: center;
  height: 50px;
  border-radius: 12px;
  line-height: 50px; */
 
 }

 /*
 .sidebar.active .nav-item{
  opacity:1;
 }

.sidebar ul li .tooltip{
  position:absolute;
  left:15%;
  top: 25%;
  transform: translate(-50% -50%);
  box-shadow:#04aa6d;
  padding:.4rem,1.2rem;
  
} */


  /* .side ul{
    list-style: none;
  }
  .side ul li{
    
    display:flex;
    justify-content: flex-start;
    align-items: center;
    display: block;
  } */

  /* @media screen and(min-width:780px) { 
    .toggleMenu{
      cursor: pointer;
      display:block;
     
    }
  } */



 /* .toggleMenu{
      cursor: pointer;
      display:none;
     
    } */

    /* @media   (min-width: 320px) {
      #top-bar {
          display: none;
      }
  } */

  .menuIcon{
    color:#04aa6d
  }


.profile-wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;
}
.profile-image{
  max-width: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-name{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1.6;
  
}

.sub-menu-wrap{
  position: absolute;
  top:100%;
  right: 10px;
  width: 320px;
  z-index: 5;
 
  max-height: 0px;
  overflow: hidden;
  transition:all 0.1s;
  
}

.sub-menu{
  background-color: #fff;
  padding: 20px;
  margin:5px;
  z-index: 5;
  border-radius: 5px;
  width: 100%;
  border:1px solid #ccc;
 

}

@media screen and (max-width: 480px) {
  /* .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
  .column.side1,.column.middle1{
    width:100%;
  }
  .container1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
}

  @media screen and (max-width: 850px) {
    .sub-menu-wrap{
      width: 98%;
      right:2;
    }
    .toggleMenu{
      cursor: pointer;
     /* display: block; */
    }
    .container {
      width: 100%;
      min-height: 80vh;
      margin-bottom: 20px;
      /* flex-direction: column;
      justify-content: center;
      align-items: center; */
    }
    .loginInner{
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      margin-bottom:10%
    
    }
  
    .loginOuterSecondary{
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      height: 100%;
      justify-content: flex-start;
     
    
    }
     .loginOuterPrimary{
      flex-direction: column;
      height: 100vh;
      padding:0;
      margin:0;
      
      justify-content: flex-start;
      align-items: center;
     
    
     
    
    }
    .item{
      padding:10px;
      width:100%;
      flex-grow: 1;
      height: 100%;
    }
    .itemRight{
      width:100%;
      padding:10;
      flex-grow: 1;
      height: 100%;
     
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      
    }
    .imageWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    }
    
    .column.middle
    {
      width: 80%;
      min-height: 80vh;
    }
    .column.side{
     width: 20%;
     height: 83vh;
    }

    .column.middle1
    {
      width: 80%;
      min-height: 80vh;
    }
    .column.side1{
     width: 20%;
     height: 83vh;
    }
   .column.right{
      display: none;
    }
    .col-25,
    .col-40,
    .col-50,
    .col-60,
    .col-75,
    input[type="submit"] {
      width: 100%;
      margin-top: 0;
    }
  
    .side ul li{
      position: relative;
      list-style-type:none;
      height: auto;
      width: 100%;
      /* margin:0px auto; */
      margin:0px;
      font-size: 14px;
      line-height: auto;
      margin-bottom:10px;
      /* border:1px solid #ccc */
      
      
     
    }
   
    .side ul li a{
   
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
     border-radius: 0.8rem; 
     width: 100%;
      /* padding-left: 0px;
      gap:0px;
      color:#3c3d3c;
      background-color: #97cc8d; */
      box-sizing: border-box;
      
      
    } 

    
   

    
     .nav-item{
     

      height: 40px;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items:center;
      text-align: center;
      line-height: 14px;
      
      
      
     }
     
    
  }
  .chat-wrapper{
    min-width:300px;
    background-color: #eeeded;
    border-radius: 5px;
    padding:10px;
    margin:5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }


  .chat-header{
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
  }

  .chat-body{
    line-height: 1.6;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 13px;
  }

  .chat-timestamp{
      color: rgb(23, 44, 83);
      font-size: 11px;
      font-weight: 500;
  }

  .chat-username{
    color: rgb(131, 131, 134);
    font-size: 12px;
    text-transform: capitalize;
  }
  
.plainContent{
  padding: 5px;
  padding-left: 10px;
}

.error-modal {
  width: 100%;
  padding: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.row_container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

}
  
.column.middle1
    {
      width: 80%;
      min-height: 80vh;
    }
    .column.side1{
     width: 20%;
     height: 83vh;
    }
